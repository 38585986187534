/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../sass/layout.scss"

const Layout = ({ children }) => {
  return (
    <>
      <header className="logo">
        <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1">
          <path d="M30,29.7024459 C30,27.9356955 28.6432256,26.5028505 26.9694301,26.5028505 C26.0591269,26.5028505 25.2450623,26.9287991 24.6895207,27.5990008 L24.6895207,27.5902348 C24.6178136,27.7022007 24.4962888,27.7759149 24.3585357,27.7759149 C24.1377532,27.7759149 23.9592402,27.5874456 23.9592402,27.3543493 L23.9592402,26 L18,26 L18,39 L23.9584853,39 L23.9584853,32.0461595 C23.9584853,31.8122663 24.1373758,31.6245939 24.3574034,31.6245939 C24.4940244,31.6245939 24.6140395,31.6955189 24.686124,31.805891 L24.686124,31.8031018 C25.242043,32.4752958 26.0576173,32.9028382 26.9694301,32.9028382 C28.6432256,32.9028382 30,31.4703917 30,29.7024459 M40.7132039,28.8875746 C40.1142351,27.074195 38.4474341,26.1037149 36.5727866,26.1037149 C34.8278418,26.1037149 33.5517603,26.8190342 32.3542254,27.9941168 L32.3542254,20 L26,20 L26,25.8876577 C26.2964634,25.8201152 26.6054137,25.7833815 26.9228228,25.7833815 C29.1736889,25.7833815 30.9983888,27.572667 30.9983888,29.7790574 C30.9983888,31.9874228 29.1736889,33.7767083 26.9228228,33.7767083 C26.6054137,33.7767083 26.2964634,33.7395797 26,33.6724321 L26,39 L32.3542254,39 L32.3542254,31.9348897 C32.3771852,31.3341164 32.879884,30.8542087 33.4977846,30.8542087 C34.1301861,30.8542087 34.6437606,31.3566306 34.6437606,31.9771532 L34.6457746,31.9771532 L34.6457746,39 L41,39 L41,31.1599277 C41,30.3940503 40.9476355,29.6281729 40.7132039,28.8875746" id="rh-logo" fill="#FFFFFF"></path>
        </svg>
      </header>

      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
